import React, { Fragment } from 'react'
import { useEffect, useState } from 'react'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'

const Accural = (props) => {
	const { modules } = useSelector((store) => store.session)

	const normalizeAmount = (val) => {
		if (!val) return null
		let norm = val * 100
		return parseFloat(norm.toFixed())
	}
	const formatAmount = (input) => {
		if (input === 0) return 0
		if (!input) return null

		return input / 100
	}
	const [showCustomFields, setShowCustomFields] = useState(false)
	const [showConfirm, setShowConfirm] = useState(true)
	const [currentCreditEmployee, setCurrentCreditEmployee] = useState({})
	const {
		handleSubmit,
		onSubmit,
		manifest,
		cancelHandler,
		onchangeHandler,
		change,
		salary,
		salaryCard,
		businessTrip,
		premium,
		mulct,
		fitness,
		employees,
		creditEmployees,
		customField,
		lunch,
		insurance,
		fuel,
		fuelCard,
		ride,
		rentCar,
		owertime,
		credit,
		RenderSelectField,
		RenderDatePicker,
		RenderCommentField,
		required,
	} = props
	useEffect(() => {
		// const sumAccrual = salary + premium - salaryCard - mulct + owertime
		const sumAccrual =
			salary +
			premium +
			ride +
			owertime +
			lunch +
			fuel -
			salaryCard -
			businessTrip -
			mulct -
			fitness -
			insurance -
			fuelCard -
			rentCar -
			credit
		// const totalAmmount = (sumAccrual + lunch + fuel - insurance - fuelCard) * -1
		const totalAmmount = sumAccrual * -1
		change('operationReportFormAccrual', 'sum', sumAccrual)
		change('operationReportFormAccrual', 'total', totalAmmount)
		if (customField) {
			const obj = JSON.parse(customField)
			const { id, payrollerId, firstName, lastName, patronymic } = obj
			if (!payrollerId && showConfirm) {
				window.confirm(
					'Для данного сотрудника не указан "Начисляющий". Если необходимо указать, обратитесь в тех. поддержку! Хотите продолжить?'
				) && setShowConfirm(false)
			}
			const counterparty = `${lastName} ${firstName} ${patronymic}`
			change('operationReportFormAccrual', 'employeeid', id)
			change('operationReportFormAccrual', 'payrollerId', payrollerId)
			change('operationReportFormAccrual', 'counterparty', counterparty)
			if (creditEmployees.length > 0) {
				const employeWithCredit = creditEmployees
					.filter((employee) => employee.employeeId === id)
					.map((i) => i)
				if (employeWithCredit[0])
					change(
						'operationReportFormAccrual',
						'creditTotal',
						employeWithCredit[0].sum * -1
					)
				setCurrentCreditEmployee(employeWithCredit[0])
			}
		}
	}, [
		salary,
		premium,
		salaryCard,
		mulct,
		change,
		customField,
		owertime,
		lunch,
		fuel,
		insurance,
		fuelCard,
		ride,
		rentCar,
		credit,
		creditEmployees,
		businessTrip,
		showConfirm,
		fitness,
	])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='modal is-active'>
				<div className='modal-background'></div>
				<div className='modal-card'>
					<header className='modal-card-head'>
						<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
						<button
							className='delete'
							aria-label='close'
							onClick={cancelHandler}
						></button>
					</header>
					<section className='modal-card-body'>
						{/* <pre>
								<code>{selectType}</code>
							</pre> */}
						<div className='container'>
							<div className='columns is-centered'>
								<div className='column is-10'>
									<div className='level is-centered'>
										<div className='level-item '>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Операция</label>
												</div>
											</div>
											<div className='control control-custom'>
												<div className='select is-fullwidth'>
													<Field
														name={'operationType'}
														component={'select'}
														onChange={(e) => {
															change(
																'operationReportFormAccrual',
																'operationType',
																e.target.value
															)
															onchangeHandler(e)
														}}
													>
														{manifest.OPERATION_TYPES.filter(
															(i) => modules[i.key]
														).map((type, i) => {
															return (
																<option key={i} data-key={type.key}>
																	{type.value}
																</option>
															)
														})}
													</Field>
												</div>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item '>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Дата начисления</label>
												</div>
											</div>
											<Field
												name={'dateAccrual'}
												component={RenderDatePicker}
											></Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item '>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Тип</label>
												</div>
											</div>
											<Field
												name={'typeAccrual'}
												component={RenderSelectField}
												validate={required}
											>
												<option value='' disabled>
													Выберите тип...
												</option>
												{manifest.ACCRUAL_TYPES.map((type, i) => {
													return <option key={i}>{type}</option>
												})}
											</Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сотрудники</label>
												</div>
											</div>
											<Field
												name={'customField'}
												component={RenderSelectField}
												validate={required}
											>
												<option value='' disabled>
													Выберите сотрудника...
												</option>
												{employees.map((type, i) => {
													return (
														<option
															key={i}
															label={`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}
															value={JSON.stringify(type.manId)}
														>{`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}</option>
													)
												})}
											</Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Зарплата</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'salary'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Премия</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'premium'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Выезды</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'ride'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Переработки</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'owertime'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Зарплата на карту</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'salaryCard'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Командировка на карту</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'businessTrip'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Удержание штрафа</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'mulct'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Фитнес</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'fitness'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									{currentCreditEmployee && currentCreditEmployee.sum && (
										<>
											{/* <div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label'>
																Кредит (задолженность)
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															name={'creditTotal'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
															disabled={true}
														></Field>
													</div>
												</div>
											</div> */}
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label'>Кредит (возврат)</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'credit'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
										</>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label secondary-messages'>
														Дополнительные поля
													</label>
												</div>
												<div className='level is-centered'>
													{!showCustomFields && (
														<div
															className='icon-box'
															onClick={() => setShowCustomFields(true)}
														>
															<i className='fas fa-chevron-down'></i>
														</div>
													)}
												</div>
												{showCustomFields && (
													<div
														className='icon-box'
														onClick={() => setShowCustomFields(false)}
													>
														<i className='fas fa-chevron-up'></i>
													</div>
												)}
											</div>
										</div>
									</div>
									{showCustomFields && (
										<Fragment>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Обед
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'lunch'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																ГСМ
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'fuel'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Страховка
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'insurance'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Топливная карта
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'fuelCard'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Аренда ТС
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'rentCar'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
										</Fragment>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сумма</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'sum'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Комментарий</label>
												</div>
											</div>
											<Field
												type={'text'}
												name={'comment'}
												component={RenderCommentField}
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className='modal-card-foot'>
						<button className='button is-success' type='submit'>
							{manifest.MODALE_SUBMITE}
						</button>
						<button className='button' onClick={cancelHandler}>
							{manifest.MODALE_CANCEL}
						</button>
					</footer>
				</div>
			</div>
		</form>
	)
}

const selector = formValueSelector('operationReportFormAccrual')
const mapStateToProps = (state) => {
	const {
		salary,
		salaryCard,
		businessTrip,
		premium,
		mulct,
		fitness,
		employeeid,
		customField,
		lunch,
		insurance,
		fuel,
		fuelCard,
		ride,
		rentCar,
		owertime,
		credit,
	} = selector(
		state,
		'salary',
		'salaryCard',
		'businessTrip',
		'premium',
		'mulct',
		'fitness',
		'employeeid',
		'customField',
		'lunch',
		'owertime',
		'credit',
		'insurance',
		'fuel',
		'fuelCard',
		'ride',
		'rentCar'
	)
	const { employees } = state.operationReport
	const { creditEmployees } = state.operationReport
	return {
		salary,
		salaryCard,
		businessTrip,
		premium,
		mulct,
		fitness,
		employees,
		creditEmployees,
		employeeid,
		customField,
		lunch,
		insurance,
		fuel,
		fuelCard,
		ride,
		rentCar,
		owertime,
		credit,
	}
}

// Decorate form with dispatchable actions
const mapDispatchToProps = {
	change,
}

// create a redux form, then include the above decorators for the created form to utilize
export default reduxForm({
	form: 'operationReportFormAccrual',
	initialValues: {
		operationType: 'Начисление',
		username: 'Peter',
		counterparty: 'Выберите сотрудника...',
		employeeid: null,
		sum: null,
		salary: null,
		salaryCard: null,
		businessTrip: null,
		premium: null,
		mulct: null,
		fitness: null,
		lunch: null,
		owertime: null,
		credit: null,
		total: null,
		insurance: null,
		fuel: null,
		fuelCard: null,
		ride: null,
		rentCar: null,
		customField: '',
		dateAccrual: new Date(),
	},
})(connect(mapStateToProps, mapDispatchToProps)(Accural))
