export const MODALE_TITLE = 'Редактирование'
export const MODALE_SUBMITE = 'Применить'
export const MODALE_CANCEL = 'Отмена'

export const columnsForAccrualTable = [
	{
		name: 'Выдано',
		prop: 'isDoneForPayroller',
	},
	{
		name: 'Дата',
		prop: 'dateAccrual',
	},
	{
		name: 'ФИО',
		prop: 'name',
	},
	{
		name: 'Сумма аванса',
		prop: 'salaryA',
	},
	{
		name: 'Аванс на карту',
		prop: 'salaryCardA',
	},
	{
		name: 'Аванс наличными',
		prop: 'totalA',
	},
	{
		name: 'Сумма отпуска/БЛ',
		prop: 'salaryO',
	},
	{
		name: 'Отпуск/БЛ на карту',
		prop: 'salaryCardO',
	},
	{
		name: 'Отпуск/БЛ наличными',
		prop: 'totalO',
	},
	{
		name: 'З/П',
		prop: 'salary',
	},
	{
		name: 'Премия',
		prop: 'premium',
	},
	{
		name: 'Выезды',
		prop: 'ride',
	},
	{
		name: 'Переработки',
		prop: 'owertime',
	},
	{
		name: 'З/П на карту',
		prop: 'salaryCard',
	},
	{
		name: 'Командировка на карту',
		prop: 'businessTrip',
	},
	{
		name: 'Топливная карта',
		prop: 'fuelCard',
	},
	{
		name: 'Штраф',
		prop: 'mulct',
	},
	{
		name: 'Фитнес',
		prop: 'fitness',
	},
	{
		name: 'Страховка',
		prop: 'insurance',
	},
	{
		name: 'Обед',
		prop: 'lunch',
	},
	{
		name: 'ГСМ',
		prop: 'fuel',
	},
	{
		name: 'Аренда ТС',
		prop: 'rentCar',
	},
	{
		name: 'Кредит/Возврат',
		prop: 'credit',
	},
	{
		name: 'Долг перед компанией',
		prop: 'creditTotal',
	},
	{
		name: 'Сумма к выплате наличными',
		prop: 'total',
	},
]
export const ACCRUAL_TYPES = ['Аванс', 'Зарплата', 'Отпуск/БЛ']
