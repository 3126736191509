import { combineReducers } from 'redux'
import * as types from './types'
import * as utils from './utils'
import * as constants from './constants'
import { createReducer, calculatePages } from '../../utils'
import { sortJust } from '../../utils/sortBy'

/* State shape
{
    details: product,
    list: [ product ],
}
*/
const tableProps = {
	offset: false,
	limit: 14,
	page: 1,
	pages: [],
	showedOn: 1,
	showedFor: 0,
	canBack: false,
	canFwd: true,
	predicate: 'createdAt',
	reverse: true,
	onlyOperations: null,
	searchFilter: {
		username: null,
		isDelete: false,
		counterparty: null,
		startDate: null,
		endDate: null,
		subdivision: null,
		operationType: null,
		totalSearch: '',
	},
}
const accrualInit = {
	total: null,
	salary: null,
	salaryCard: null,
	premium: null,
	mulct: null,
	lunch: null,
	owertime: null,
	fuel: null,
	insurance: null,
}

const loadingReducer = createReducer(true)({
	[types.FETCH_REPORT_COMPLETED]: () => false,
	[types.FETCH_REPORT_FAILED]: () => false,
	[types.FETCH_AMMOUNT_FAILED]: () => false,
})

const reportReducer = createReducer([])({
	[types.FETCH_REPORT_COMPLETED]: (state, action) => {
		const { data } = action.payload
		if (data) {
			return (action.payload.operations = utils.dataFormatter(data))
		}
		return []
	},
	[types.FETCH_REPORT_FAILED]: (state, action) => {
		return []
	},
})
const ammountReducer = createReducer({})({
	[types.FETCH_AMMOUNT_COMPLETED]: (state, action) => {
		let ammountTotal = {}
		const { ammount } = action.payload
		const { ammountOfSubdivisons } = action.payload
		if (ammount) {
			ammountTotal.ammount = utils.toRoundAmmountAndFormatted(ammount)
		}
		if (ammountOfSubdivisons && Object.keys(ammountOfSubdivisons).length > 0) {
			for (const key in ammountOfSubdivisons) {
				if (ammountOfSubdivisons.hasOwnProperty(key)) {
					const element = ammountOfSubdivisons[key]
					ammountTotal[key] = utils.toRoundAmmountAndFormatted(element)
				}
			}
		}
		return ammountTotal
	},
})
const totalReducer = createReducer(0)({
	[types.FETCH_REPORT_COMPLETED]: (state, action) => action.payload.total,
	[types.FETCH_REPORT_FAILED]: (state, action) => {
		return 0
	},
})

const tableReducer = createReducer(tableProps)({
	[types.ADD_SESSION]: (state, action) => {
		const { searchFilter } = state
		const { modules, people } = action.payload
		if (modules?.accounting) return state
		state.onlyOperations = utils.checkOperationRoles(
			constants.OPERATION_TYPES,
			modules
		)
		const subdivision = utils.getSubdivision(modules)
		if (modules?.accountingOnlyCanSeeMyOperations) {
			const username = people?.fullname
			searchFilter.username = username
		}
		if (subdivision !== 'accountingMoscow') {
			searchFilter.subdivision = subdivision
		}
		return { ...state, searchFilter }
	},
	[types.FETCH_REPORT_COMPLETED]: (state, action) => {
		const { total, operations } = action.payload
		const { limit, page } = state
		const { pages, showedOn, showedFor, canBack, canFwd } = calculatePages(
			total,
			limit,
			page,
			operations
		)
		return { ...state, pages, showedOn, showedFor, canBack, canFwd }
	},
	[types.STEP_FORWARD]: (state, action) => {
		let { page, canFwd, offset } = state
		offset = true
		if (canFwd) page++
		return { ...state, page, offset }
	},
	[types.STEP_BACK]: (state, action) => {
		let { page, canBack, offset } = state
		offset = true

		if (canBack) page--
		return { ...state, page, offset }
	},
	[types.STEP_TO_CHOSEN]: (state, action) => {
		const { pageNumber } = action.payload
		let { offset } = state
		offset = true

		return { ...state, page: pageNumber, offset }
	},
	[types.FILTER_BY_DATE]: (state, action) => {
		const { dateRange } = action.payload
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1

		if (dateRange && dateRange.length > 0) {
			searchFilter.startDate = dateRange[0]
			searchFilter.endDate = dateRange[1]
		} else {
			searchFilter.startDate = null
			searchFilter.endDate = null
		}
		return { ...state, searchFilter, offset, page }
	},
	[types.FILTER_BY_ALL]: (state, action) => {
		const { criteria } = action.payload
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1
		if (criteria) {
			searchFilter.totalSearch = criteria
		} else {
			searchFilter.totalSearch = ''
		}
		return { ...state, searchFilter, offset, page }
	},
	[types.FILTER_BY_SUBDIVISION]: (state, action) => {
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1
		const { subdivision } = action.payload
		if (subdivision === 'all') {
			searchFilter.subdivision = null
			return { ...state, searchFilter }
		}
		searchFilter.subdivision = subdivision
		return { ...state, searchFilter, offset, page }
	},
	[types.FILTER_BY_OPERATION]: (state, action) => {
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1
		const { operation } = action.payload
		if (!operation) {
			searchFilter.operationType = null
			return { ...state, searchFilter }
		}
		searchFilter.operationType = operation
		return { ...state, searchFilter, offset, page }
	},
	[types.FILTER_BY_OPERATION_STATE]: (state, action) => {
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1
		const { operationState } = action.payload
		if (operationState === null || operationState === undefined) {
			return { ...state, searchFilter }
		}
		searchFilter.isDelete = operationState
		return { ...state, searchFilter, offset, page }
	},
	[types.FETCH_REPORT_FAILED]: (state, action) => {
		return tableProps
	},
})

const formProps = createReducer('')({
	[types.SELECT_TYPE]: (state, action) => action.payload.type,
})
const detailsOperationReducer = createReducer({})({
	[types.SHOW_DETAILS_OPERATION]: (state, action) => action.payload,
})

const employeesReducer = createReducer([])({
	[types.FETCH_EMPLOYEES_COMPLETED]: (state, action) =>
		sortJust(
			(a, b) =>
				a.manId.lastName > b.manId.lastName
					? 1
					: b.manId.lastName > a.manId.lastName
					? -1
					: 0,
			action.payload.data
		),
})
const subdivisionsReducer = createReducer([])({
	[types.FETCH_SUBDIVISIONS_COMPLETED]: (state, action) =>
		action.payload.data &&
		action.payload.data
			.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
			.map((subdivision) =>
				subdivision.name.replace(' (отдел установки и сопровождения)', '')
			),
})
const employeesCreditReducer = createReducer([])({
	[types.FETCH_CREDIT_COMPLETED]: (state, action) => action.payload.data,
	[types.FETCH_CREDIT_FAILED]: (state, action) => {
		return []
	},
})
const accrualsEmployeesReducer = createReducer([])({
	[types.FETCH_ACCRUALS_COMPLETED]: (state, action) => action.payload.data,
	[types.FETCH_ACCRUALS_FAILED]: (state, action) => {
		return []
	},
	[types.PUT_DONE_ACCRUAL_COMPLETED]: (state, action) => {
		return []
	},
	[types.PUT_DONE_ACCRUAL_FAILED]: (state, action) => {
		return []
	},
	[types.POST_ACCRUAL_COMPLETED]: (state, action) => {
		return []
	},
	[types.POST_ACCRUAL_FAILED]: (state, action) => {
		return []
	},
	[types.DELETE_ACCRUAL_COMPLETED]: (state, action) => {
		return []
	},
	[types.DELETE_ACCRUAL_FAILED]: (state, action) => {
		return []
	},
})

const accrualEmployeesReducer = createReducer(accrualInit)({
	[types.GET_ONE_ACCRUAL]: (state, action) => {
		const { id, accruals } = action.payload
		const accrual = accruals.find((a) => a.id === id)
		if (accrual) return accrual
		return accrualInit
	},
	[types.PUT_DONE_ACCRUAL_COMPLETED]: (state, action) => {
		return accrualInit
	},
	[types.PUT_DONE_ACCRUAL_FAILED]: (state, action) => {
		return accrualInit
	},
	[types.POST_ACCRUAL_COMPLETED]: (state, action) => {
		return accrualInit
	},
	[types.POST_ACCRUAL_FAILED]: (state, action) => {
		return accrualInit
	},
	[types.DELETE_ACCRUAL_COMPLETED]: (state, action) => {
		return accrualInit
	},
	[types.DELETE_ACCRUAL_FAILED]: (state, action) => {
		return accrualInit
	},
})

export default combineReducers({
	isLoading: loadingReducer,
	report: reportReducer,
	ammount: ammountReducer,
	total: totalReducer,
	table: tableReducer,
	formProps,
	employees: employeesReducer,
	subdivisions: subdivisionsReducer,
	accrual: accrualEmployeesReducer,
	accrualsByEmployee: accrualsEmployeesReducer,
	creditEmployees: employeesCreditReducer,
	detailsOperation: detailsOperationReducer,
})
