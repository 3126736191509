import moment from 'moment'
import 'moment/locale/ru'
import * as constants from './constants'
moment.locale('ru')
const formatter = new Intl.NumberFormat('ru')

export function calculateUrl(props) {
	if (!props) return ''
	let url = getUrlWithFilters(props.searchFilter)
	url += `&limit=${props.limit}`
	if (props.offset) {
		const offset = props.limit * (props.page - 1)
		url += `&offset=${offset}`
	}
	if (props.onlyOperations) {
		url += `&onlyOperations=${props.onlyOperations}`
	}
	url += `&orderBy=${props.reverse ? '-' : ''}${props.predicate}`
	return url
}

export function calculateReportUrl(tableProps, reportType) {
	let url = calculateUrl(tableProps)
	return `${url}&reportType=${reportType}`
}

export function calculateUrlForAmmount(props) {
	if (!props) return '/operations/ammount'
	let url = '/operations/ammount?'
	if (props.accountingMoscow) return (url += `&subdivision=accountingMoscow`)
	if (props.accountingMoscowPayout)
		return (url += `&subdivision=accountingMoscowPayout`)
	if (props.accountingSPB) return (url += `&subdivision=accountingSPB`)
	if (props.accountingKrasnodar)
		return (url += `&subdivision=accountingKrasnodar`)
	if (props.accountingNSK) return (url += `&subdivision=accountingNSK`)
	if (props.accountingAkshenov)
		return (url += `&subdivision=accountingAkshenov`)
	return url
}

function getUrlWithFilters(filter) {
	let url = '?'
	let value
	for (const filt in filter) {
		if ((filt === 'startDate' || filt === 'endDate') && filter[filt] !== null) {
			value = JSON.stringify(filter[filt])
		} else {
			value = filter[filt]
		}
		if (value) {
			url += `&${filt}=${value}`
		}
	}
	return url
}

export function checkOperationRoles(opTypes, userRoles) {
	let query = ''
	const operations = opTypes
		.filter((type) => userRoles[type.key] && type.value)
		.map((i) => i.value)
	query = operations.join(', ')
	return query
}

export function dataFormatter(unFormattedResponce) {
	let formatted
	if (Array.isArray(unFormattedResponce) && unFormattedResponce.length !== 0) {
		formatted = unFormattedResponce.map((el) => {
			return {
				id: el.id,
				username: el.username,
				modifyrerName: el.modifyrerName || undefined,
				operationType: el.operationType,
				typeAccrual: el.typeAccrual || undefined,
				counterparty: el.counterparty,
				salary: toRoundAmmountAndFormatted(el.salary)
					? `${toRoundAmmountAndFormatted(el.salary)} руб.`
					: null,
				salaryCard: toRoundAmmountAndFormatted(el.salaryCard)
					? `${toRoundAmmountAndFormatted(el.salaryCard)} руб.`
					: null,
				businessTrip: toRoundAmmountAndFormatted(el.businessTrip)
					? `${toRoundAmmountAndFormatted(el.businessTrip)} руб.`
					: null,
				fuelCard: toRoundAmmountAndFormatted(el.fuelCard)
					? `${toRoundAmmountAndFormatted(el.fuelCard)} руб.`
					: null,
				rentCar: toRoundAmmountAndFormatted(el.rentCar)
					? `${toRoundAmmountAndFormatted(el.rentCar)} руб.`
					: null,
				premium: toRoundAmmountAndFormatted(el.premium)
					? `${toRoundAmmountAndFormatted(el.premium)} руб.`
					: null,
				mulct: toRoundAmmountAndFormatted(el.mulct)
					? `${toRoundAmmountAndFormatted(el.mulct)} руб.`
					: null,
				fitness: toRoundAmmountAndFormatted(el.fitness)
					? `${toRoundAmmountAndFormatted(el.fitness)} руб.`
					: null,
				lunch: toRoundAmmountAndFormatted(el.lunch)
					? `${toRoundAmmountAndFormatted(el.lunch)} руб.`
					: null,
				owertime: toRoundAmmountAndFormatted(el.owertime)
					? `${toRoundAmmountAndFormatted(el.owertime)} руб.`
					: null,
				fuel: toRoundAmmountAndFormatted(el.fuel)
					? `${toRoundAmmountAndFormatted(el.fuel)} руб.`
					: null,
				ride: toRoundAmmountAndFormatted(el.ride)
					? `${toRoundAmmountAndFormatted(el.ride)} руб.`
					: null,
				insurance: toRoundAmmountAndFormatted(el.insurance)
					? `${toRoundAmmountAndFormatted(el.insurance)} руб.`
					: null,
				credit: toRoundAmmountAndFormatted(el.credit)
					? `${toRoundAmmountAndFormatted(el.credit)} руб.`
					: null,
				cashless: el.cashless || false,
				isDelete: el.isDelete || false,
				sum: toRoundAmmountAndFormatted(el.sum)
					? `${toRoundAmmountAndFormatted(el.sum)} руб.`
					: '0,00 руб.',
				comment: el.comment,
				dateAccrual: el.dateAccrual
					? moment(el.dateAccrual).format('LLL')
					: undefined,
				customFields:
					el.customFieldsR && el.customFieldsR.length > 0
						? el.customFieldsR.map((c) => {
								c.sum = toRoundAmmountAndFormatted(c.sum)
									? `${toRoundAmmountAndFormatted(c.sum)}руб.`
									: '0,00 руб.'
								return c
						  })
						: null,
				createdAt: moment(el.createdAt).format('LLL'),
				updatedAt: moment(el.updatedAt).format('LLL'),
			}
		})
	}
	return formatted
}
export function toRoundAmmountAndFormatted(ammount) {
	if (!ammount) return ''
	const ammountInt = parseInt(ammount) / 100
	return formatter.format(ammountInt)
}
export function getSubdivision(modules) {
	// for (let index = 0; index < constants.SUBDIVISIONS_TYPES.length; index++) {
	// 	const element = constants.SUBDIVISIONS_TYPES[index]
	// 	if (modules[element] && element === 'accountingMoscow') {
	// 		return element
	// 	} else if (modules[element]) return element
	// }

	// Позорнейший костыль, пилю из-за того, что мои полномочия все
	// Я либо перепишу это с нуля, либо пока только так
	// Выше закомментирован кусок кода, с которого это было переписано,
	// чтобы было понятно, почему я хочу проводить минимум времени
	// за допиливанием этой поделки
	// До этого то, на какой аккаунт упадёт деньга, сильно зависело от порядка
	// подразделений в SUBDIVISIONS_TYPES
	if (modules['accountingMoscow']) return 'accountingMoscow'
	return constants.SUBDIVISIONS_TYPES.find(
		(subdivision) => modules[subdivision]
	)
}
