export const MODALE_TITLE = 'Добавить операцию'
export const MODALE_SUBMITE = 'Создать'
export const MODALE_CANCEL = 'Отмена'

export const OPERATION_TYPES = [
	{ key: 'accountingIncoming', value: 'Приход' },
	{ key: 'accountingOutgo', value: 'Расход' },
	{ key: 'accountingAccrual', value: 'Начисление' },
	{ key: 'accountingPayout', value: 'Выплата' },
	{ key: 'accountingCredit', value: 'Кредит' },
]

export const COUNTERPARTY_GROUPS = [
	'Сотрудники',
	'Клиенты',
	'Услуги',
	'Основные',
	'Поставщики',
]
export const COUNTERPARTY_OUTGO_GROUPS = [
	'Услуги',
	'Основные',
	'Внутренние перемещения',
]
export const COUNTERPARTY_OUTGO_BASE_TYPES = [
	'Яндекс кошелек',
	'Хоз. расходы',
	'Транспортные расходы',
	'Страховка',
	'Снятие со счета',
	'СМС-Сервер',
	'Прочее',
	'Переработки',
	'Обед',
	'Комиссия',
	'Командировки',
	'Стажировки',
	'Зарплата на карту',
	'Аренда квартиры',
]
export const COUNTERPARTY_OUTGO_SERVICES_TYPES = [
	'Установка x-keeper',
	'Уборка',
	'Телефонная связь',
	'Реклама',
	'Программист 1С',
	'Почта',
	'Кредит',
	'Коммунальные платежи',
	'Интернет',
	'Бензин',
	'Аренда',
]
export const COUNTERPARTY_INCOMING_GROUPS = [
	'Основные',
	'Внутренние перемещения',
]
export const COUNTERPARTY_INCOMING_BASE_TYPES = [
	'Снятие со счета',
	'Клиенты',
	'Прочее',
	'Товары',
]
export const INCOMING_PRODUCTS = [
	'3Dl',
	'Duos s',
	'Duos',
	'GLONASS CONTAINER - TRACKER',
	'SM X-Keeper Invis DUOS Online Lite',
	'Магнит',
	'Чехлы',
	'Батарейки',
]
export const SUBDIVISIONS_TYPES = [
	'accountingMoscowPayout',
	'accountingMoscow',
	'accountingSPB',
	'accountingAkshenov',
	'accountingKrasnodar',
	'accountingNSK',
]
export const CREDIT_OPERATION_TYPES = ['Выдача', 'Возврат']
export const ACCRUAL_TYPES = ['Аванс', 'Зарплата', 'Отпуск/БЛ']

export const REPORT_TYPE = {
	default: 'default',
	formal: 'formal',
}
