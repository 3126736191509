import React, { Fragment } from 'react'
import { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { change } from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { reportOperations } from 'state/entities/operationReport'
import { parseAndFormat } from 'state/entities/accrualReport/utils'
import { useSelector } from 'react-redux'

const Payout = (props) => {
	const { modules } = useSelector((store) => store.session)
	const { accrualsByEmployee } = useSelector((store) => store.operationReport)

	const normalizeAmount = (val) => {
		if (!val) return ''
		let norm = val * 100
		return parseFloat(norm.toFixed())
	}
	const formatAmount = (input) => {
		if (!input) return ''

		return input / 100
	}
	const [showCustomFields, setshowCustomFields] = useState(false)
	const [currentCreditEmployee, setCurrentCreditEmployee] = useState({})
	const {
		handleSubmit,
		onSubmit,
		manifest,
		cancelHandler,
		onchangeHandler,
		change,
		employees,
		creditEmployees,
		accrual,
		reset,
		RenderSelectField,
		RenderCommentField,
		required,
	} = props
	const dispatch = useDispatch()
	const handleEmployeeSelect = (e) => {
		const employeeId = +e.target.value
		const employee = employees.find((e) => e.manId.id === employeeId)
		if (employee) {
			const { firstName, lastName, patronymic } = employee.manId
			const counterparty = `${lastName} ${firstName} ${patronymic}`
			change('operationReportFormPayout', 'employeeid', employeeId)
			change('operationReportFormPayout', 'counterparty', counterparty)
			dispatch(reportOperations.fetchAccrualsEmployee(employeeId))
		}
	}
	const handleAccrualSelect = (e) => {
		const obj = JSON.parse(e.target.value)
		const { id } = obj
		dispatch(reportOperations.getOneAccrual(id, accrualsByEmployee))
	}
	useEffect(() => {
		change('operationReportFormPayout', 'accrualId', accrual.id)
		change('operationReportFormPayout', 'sum', accrual.total)
		change('operationReportFormPayout', 'salary', accrual.salary)
		change('operationReportFormPayout', 'salaryCard', accrual.salaryCard)
		change('operationReportFormPayout', 'businessTrip', accrual.businessTrip)
		change('operationReportFormPayout', 'premium', accrual.premium)
		change('operationReportFormPayout', 'mulct', accrual.mulct)
		change('operationReportFormPayout', 'fitness', accrual.fitness)
		change('operationReportFormPayout', 'lunch', accrual.lunch)
		change('operationReportFormPayout', 'owertime', accrual.owertime)
		change('operationReportFormPayout', 'fuel', accrual.fuel)
		change('operationReportFormPayout', 'fuelCard', accrual.fuelCard)
		change('operationReportFormPayout', 'ride', accrual.ride)
		change('operationReportFormPayout', 'rentCar', accrual.rentCar)
		change('operationReportFormPayout', 'insurance', accrual.insurance)
		change('operationReportFormPayout', 'credit', accrual.credit)
		if (creditEmployees.length > 0) {
			const employeWithCredit = creditEmployees
				.filter((employee) => employee.employeeId === accrual.employeeId)
				.map((i) => i)
			if (employeWithCredit[0])
				change(
					'operationReportFormPayout',
					'creditTotal',
					employeWithCredit[0].sum * -1
				)
			setCurrentCreditEmployee(employeWithCredit[0])
		}
	}, [
		accrual.id,
		accrual.total,
		accrual.salary,
		accrual.salaryCard,
		accrual.premium,
		accrual.mulct,
		accrual.fitness,
		accrual.lunch,
		accrual.owertime,
		accrual.fuel,
		accrual.fuelCard,
		accrual.ride,
		accrual.rentCar,
		accrual.insurance,
		accrual.credit,
		accrual,
		change,
		creditEmployees,
	])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='modal is-active'>
				<div className='modal-background'></div>
				<div className='modal-card'>
					<header className='modal-card-head'>
						<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
						<button
							className='delete'
							aria-label='close'
							onClick={cancelHandler}
						></button>
					</header>
					<section className='modal-card-body'>
						{/* <pre>
								<code>{selectType}</code>
							</pre> */}
						<div className='container'>
							<div className='columns is-centered'>
								<div className='column is-10'>
									<div className='level is-centered'>
										<div className='level-item '>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Операция</label>
												</div>
											</div>
											<div className='control control-custom'>
												<div className='select is-fullwidth'>
													<Field
														name={'operationType'}
														component={'select'}
														onChange={(e) => {
															change(
																'operationReportFormPayout',
																'operationType',
																e.target.value
															)
															onchangeHandler(e)
														}}
													>
														{manifest.OPERATION_TYPES.filter(
															(i) => modules[i.key]
														).map((type, i) => {
															return (
																<option key={i} data-key={type.key}>
																	{type.value}
																</option>
															)
														})}
													</Field>
												</div>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сотрудники</label>
												</div>
											</div>
											<Field
												component={RenderSelectField}
												validate={required}
												onChange={handleEmployeeSelect}
											>
												<option value='' disabled>
													Выберите сотрудника...
												</option>
												{employees.map((type, i) => {
													return (
														<option
															key={i}
															label={`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}
															value={type.manId?.id}
														>{`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}</option>
													)
												})}
											</Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Начисления</label>
												</div>
											</div>
											<Field
												name={'currentAccrual'}
												component={RenderSelectField}
												validate={required}
												onChange={handleAccrualSelect}
											>
												<option value='' disabled>
													Выберите начисление...
												</option>
												{accrualsByEmployee.map((accrual, i) => {
													return (
														<option
															key={i}
															label={`${
																accrual.dateAccrual
																	? moment(accrual.dateAccrual).format('DD/MM')
																	: 'дд.мм.'
															} | ${accrual.typeAccrual} | ${
																accrual.total
																	? parseAndFormat(accrual.total)
																	: '0,00'
															}`}
															value={JSON.stringify(accrual)}
														>{`${
															accrual.dateAccrual
																? moment(accrual.dateAccrual).format('DD/MM')
																: 'дд.мм.'
														} | ${accrual.typeAccrual} | ${
															accrual.total
																? parseAndFormat(accrual.total)
																: '0,00'
														}`}</option>
													)
												})}
											</Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Зарплата</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'salary'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Премия</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'premium'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Выезды</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'ride'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Переработки</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'owertime'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Зарплата на карту</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'salaryCard'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Командировка на карту</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'businessTrip'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Удержание штрафа</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'mulct'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Фитнес</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'fitness'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									{currentCreditEmployee && currentCreditEmployee.sum && (
										<div className='level is-centered'>
											<div className='level-item'>
												<div className='field is-horizontal lable-custom'>
													<div className='field-label is-normal'>
														<label className='label'>Кредит (возврат)</label>
													</div>
												</div>
												<div className='control control-custom'>
													<Field
														className='input is-fullwidth'
														placeholder={'0,00'}
														name={'credit'}
														component={'input'}
														type={'number'}
														parse={Number}
														format={formatAmount}
														normalize={normalizeAmount}
														disabled={true}
													></Field>
												</div>
											</div>
										</div>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label secondary-messages'>
														Дополнительные поля
													</label>
												</div>
												<div className='level is-centered'>
													{!showCustomFields && (
														<div
															className='icon-box'
															onClick={() => setshowCustomFields(true)}
														>
															<i className='fas fa-chevron-down'></i>
														</div>
													)}
												</div>
												{showCustomFields && (
													<div
														className='icon-box'
														onClick={() => setshowCustomFields(false)}
													>
														<i className='fas fa-chevron-up'></i>
													</div>
												)}
											</div>
										</div>
									</div>
									{showCustomFields && (
										<Fragment>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Обед
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'lunch'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
															disabled={true}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																ГСМ
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'fuel'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
															disabled={true}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Страховка
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'insurance'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
															disabled={true}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Топливная карта
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'fuelCard'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
															disabled={true}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Аренда ТС
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'rentCar'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
															disabled={true}
														></Field>
													</div>
												</div>
											</div>
										</Fragment>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сумма</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													placeholder={'0,00'}
													className='input is-fullwidth'
													name={'sum'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Комментарий</label>
												</div>
											</div>
											<Field
												type={'text'}
												name={'comment'}
												component={RenderCommentField}
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className='modal-card-foot'>
						<button className='button is-success' type='submit'>
							{manifest.MODALE_SUBMITE}
						</button>
						<button
							className='button'
							onClick={() => {
								dispatch(reset('operationReportFormPayout'))
								cancelHandler()
							}}
						>
							{manifest.MODALE_CANCEL}
						</button>
					</footer>
				</div>
			</div>
		</form>
	)
}
// const selector = formValueSelector('operationReportFormPayout')
const mapStateToProps = (state) => {
	const { employees } = state.operationReport
	const { accrual } = state.operationReport
	const { creditEmployees } = state.operationReport
	return {
		employees,
		accrual,
		creditEmployees,
	}
}

// Decorate form with dispatchable actions
const mapDispatchToProps = {
	change,
}

// create a redux form, then include the above decorators for the created form to utilize
export default reduxForm({
	form: 'operationReportFormPayout',
	initialValues: {
		operationType: 'Выплата',
		username: 'Peter',
		counterparty: 'Выберите сотрудника ...',
		// employeeid: null,
		// sum: null,
		// salary: null,
		// salaryCard: null,
		// premium: null,
		// mulct: null,
		// lunch: null,
		// owertime: null,
	},
})(connect(mapStateToProps, mapDispatchToProps)(Payout))
